import { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from '../../assets/img/footer-logo.png';
import Facebook from '../../assets/img/facebook.png';
import Youtube from '../../assets/img/youtube.png';
import Linkedin from '../../assets/img/linkedin.png';
import Skype from '../../assets/img/skype.png';

const Footer: FC = () => {
    
    return (
        <>
            <footer className="footer">
                <div className="container d-flex flex-wrap align-items-center justify-content-between">
                    <div className="copy">&copy; Climate-Smart Proejct 2024</div>
                    <div className="footerLink"><a href="#">Terms of Use</a> | <a href="#">Privacy Policy</a></div>
                </div>
            </footer>            
        </>        
    );
}
export default Footer;