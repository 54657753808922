export const dataActionTypes = {
    FATCH_PAGE_INFO: "FATCH_PAGE_INFO",
    FATCH_FAQ_LIST: "FATCH_FAQ_LIST",
    FATCH_BLOG_LIST: "FATCH_BLOG_LIST",
    FATCH_BLOG_INFO: "FATCH_BLOG_INFO",
    FATCH_TESTIMONIALS_LIST: "FATCH_TESTIMONIALS_LIST",
    ADD_CONTACT_INFO: "ADD_CONTACT_INFO",
    FATCH_SETTINGS_LIST: "FATCH_SETTINGS_LIST",
    FATCH_CATEGORY_LIST: "FATCH_CATEGORY_LIST",
    FATCH_SUBCATEGORY_LIST: "FATCH_SUBCATEGORY_LIST"
}


export const imageActionTypes = {
    FATCH_COLLECTION_LIST: "FATCH_COLLECTION_LIST",
}

export const accountActionTypes = {
    FATCH_LOGIN: "FATCH_LOGIN",
    FATCH_REGISTER: "FATCH_REGISTER",
    FATCH_CHANGE_PASSWORD: "FATCH_CHANGE_PASSWORD",
    GET_PROFILE_INFO: "GET_PROFILE_INFO",
    UPDATE_PROFILE: "UPDATE_PROFILE",
    USER_RESET_PASSWORD_LINK: "USER_RESET_PASSWORD_LINK",
    GET_LOGOUT: "GET_LOGOUT",
    VERIFY_OTP: "VERIFY_OTP"
}