export const formFields = {
    name: "Name is required",
    emailRequired: "Email is required",
    userName: "Username is required",
    email: "Invalid email address",
    phone_no:"Contact Number is required",
    message:"Message is required",
    firstName: "First Name is required",
    lastName: "Last Name is required",
    password: "Password is required",
    passwordConfirmation:  "Password Confirmation is required",
    currentPassword: "Current Password is required",
    mobile:"Mobile Number is required",
    address:"Address is required",
    city:"City is required",
    pincode:"pincode is required",
    authenticationMessage: "",
    comments:"Please Add Comments"
}
export const errorMessage = {
    error401: "You are not Logged in"
}

export const authenticationMessage = {
    somethingWrong: "Something went wrong!",
    loaderMessage: "It won't take long.",
    logout: "You have successfully logged out."
}

