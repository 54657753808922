import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
const FarmsProfileView: FC = () => {
  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4 pt-md-5 pb-md-5">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">Carter Farms Profile</span> NC_C_1
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 pt-md-5 pb-md-5 mt-2 whtBg">
          <div className="container">
           <ul className="d-flex flex-wrap p-0 viewList">
            <li className="col-6 col-md-4 col-lg-3">Farm ID <span className="d-block pt-1">NC_C_1</span></li>
            <li className="col-6 col-md-4 col-lg-3">USDA / FSA <span className="d-block pt-1">1663</span></li>
            <li className="col-6 col-md-4 col-lg-3">Acreage <span className="d-block pt-1">10 - about 5 in pasture/field</span></li>
            <li className="col-6 col-md-4 col-lg-3">Planting Zone <span className="d-block pt-1">7b</span></li>
            <li className="col-6 col-md-4 col-lg-3">Tract <span className="d-block pt-1">2415</span></li>
            <li className="col-6 col-md-4 col-lg-3">Field Numbers <span className="d-block pt-1">3,4,5,6</span></li>
            <li className="col-6 col-md-4 col-lg-3">Commodities Grown <span className="d-block pt-1">Plan for naturally grown, organic certification if possible; tomatoes, barley, squash, onion, garlic...</span></li>
            <li className="col-6 col-md-4 col-lg-3">Type Of Operation <span className="d-block pt-1">Naturally Grown</span></li>
            <li className="col-6 col-md-4 col-lg-3">Years In Production <span className="d-block pt-1">5</span></li>
            <li className="col-6 col-md-4 col-lg-3">Beginning Farmer  <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Limited Resource Farmer <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Duty Service Member (Veteran / Active) <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Race / Ethnicity <span className="d-block pt-1">White</span></li>
            <li className="col-6 col-md-4 col-lg-3">Self Describe Race Ethnicity <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">Gender <span className="d-block pt-1">Male</span></li>
            <li className="col-6 col-md-4 col-lg-3">Self Describe Gender <span className="d-block pt-1">Male</span></li>
            <li className="col-6 col-md-4 col-lg-3">Use Cover Crops <span className="d-block pt-1">No</span></li>
            <li className="col-6 col-md-4 col-lg-3">Enrolled In Other Nrcs Program  <span className="d-block pt-1">No</span></li>
            <li className="col-6 col-md-4 col-lg-3">Other Nrcs Programs <span className="d-block pt-1">We have applied for but have not been qualified</span></li>
            <li className="col-6 col-md-4 col-lg-3">Willing To Dedicate 10 Acres <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Reason Not Willing Dedicate 10 Acres <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">Willing Commit Enrolled Acreage (Spring 2028) <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Reason Not Willing Commit Enrolled Acreage (Spring 2028) <span className="d-block pt-1">Yes, although not our entire acreage, we could commit 1 acre</span></li>
            <li className="col-6 col-md-4 col-lg-3">Willing Provide Periodic Economic Data <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Reason Not Willing Provide Periodic Economic Data <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Willing Allow Scheduled Visits <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Nrcs 1270 <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">W_9 <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">FSA Waiver <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">Equipment Survey <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Cash Crop Survey <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">Enrolled Acreage <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">Equipment Type <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">Tractor hp <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">3 pt Hitch Category <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">Mower Bush Hog <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">Tiller <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">Tractor Hydraulic <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Tractor Electric <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">Seed Drill <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">Mulch Layer <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">Transplanter <span className="d-block pt-1">Yes</span></li>
            <li className="col-6 col-md-4 col-lg-3">Vehicle To Flatbed Trailer <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">Type Of Truck <span className="d-block pt-1">N/A</span></li>
            <li className="col-6 col-md-4 col-lg-3">How unload Equipment From Trailer <span className="d-block pt-1">N/A</span></li>            
            <li className="col-12">Comments <span className="d-block pt-1">Not sure if this is open to new farms, but I am interested in cover crops and organic, so seems like a good fit. I am enrolled in the Arcadia Veteran Farmer reserve for 2024 since I am new to farming. I am also interested in regenerative, no till methods.</span></li>
           </ul>
           <div className="pt-5 text-end">
            <NavLink  to="/farms-profile"><button type="button" className="formBtn cancel">Cancel</button></NavLink>
            <NavLink  to="/farms-profile/edit"><button type="button" className="ms-3 formBtn">Edit Details</button></NavLink>
           </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default FarmsProfileView;
