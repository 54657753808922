import React from "react";

interface PaginationProps {
  PageIndex: number;
  TotalPages: number;
  GoToPage: (page: number) => void;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  PageIndex,
  TotalPages,
  GoToPage,
  hasPreviousPage,
  hasNextPage,
}) => {
  const renderPageNumbers = () => {
    const list = [];
    if (TotalPages <= 7) {
      // Render all pages if total pages are less than or equal to 7
      for (let i = 1; i <= TotalPages; i++) {
        list.push(
          <a
            key={i}
            href="#"
            className={`number ${PageIndex === i ? "is-current" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              GoToPage(i);
            }}
          >
            {i}
          </a>
        );
      }
    } else {
      // Handle pagination for more than 7 pages
      const start = Math.max(2, PageIndex - 1);
      const end = Math.min(TotalPages - 1, PageIndex + 1);

      list.push(
        <a
          key={1}
          href="#"
          className={`number ${PageIndex === 1 ? "is-current" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            GoToPage(1);
          }}
        >
          1
        </a>
      );

      if (start > 2) {
        list.push(<span key="start-ellipsis" className="pagination-ellipsis">…</span>);
      }

      for (let i = start; i <= end; i++) {
        list.push(
          <a
            key={i}
            href="#"
            className={`number ${PageIndex === i ? "is-current" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              GoToPage(i);
            }}
          >
            {i}
          </a>
        );
      }

      if (end < TotalPages - 1) {
        list.push(<span key="end-ellipsis" className="pagination-ellipsis">…</span>);
      }

      list.push(
        <a
          key={TotalPages}
          href="#"
          className={`number ${PageIndex === TotalPages ? "is-current" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            GoToPage(TotalPages);
          }}
        >
          {TotalPages}
        </a>
      );
    }
    return list;
  };

  return (
    <div className="paging">
      {hasPreviousPage && (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            GoToPage(PageIndex - 1);
          }}
        >
          &lt;
        </a>
      )}
      {renderPageNumbers()}
      {hasNextPage && (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            GoToPage(PageIndex + 1);
          }}
        >
          &gt;
        </a>
      )}
    </div>
  );
};

export default Pagination;
