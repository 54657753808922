import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
const FarmsProfile: FC = () => {
  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">Farms Profile</span>
            </div>
            <div className="bradcrumb">
            <NavLink  to="/dashboard">Dashboard</NavLink> &gt; Farms Profile
            </div>
          </div>
        </section>
        <section className="filterCon">
          <div className="container">
            <div className="d-flex flex-wrap align-items-center pt-3 pb-3">
              <div className="searchBy">Search By</div>
              <ul className="d-flex flex-wrap filterBox">
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">Farm ID</span>
                    <input
                      type="text"
                      className="filterInput"
                      placeholder="NC_C_1"
                    />
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">USAD/FSA</span>
                    <input type="text" className="filterInput" />
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <select className="filterSelect">
                      <option>State</option>
                    </select>
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <select className="filterSelect">
                      <option>Status</option>
                    </select>
                  </div>
                </li>
                <li className="btn"><button type="button"><span className="material-icons">search</span></button></li>
              </ul>
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 mt-2 whtBg">
          <div className="container">
            <div className="tableBox">
              <table>
                <tr>
                  <th>Farm ID</th>
                  <th>Usda / FSA</th>
                  <th>Farm Name</th>
                  <th>Farm State</th>
                  <th>Type of Operation</th>
                  <th>Planting Zone</th>
                  <th>Action</th>
                </tr>
                <tr>
                  <td>NC_C_1</td>
                  <td>1663</td>
                  <td>Carter Farms</td>
                  <td>NC</td>
                  <td>Naturally Grown</td>
                  <td>7b</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/farms-profile/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/farms-profile/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>VA_O_2</td>
                  <td>3120</td>
                  <td>B.R. Jeffers Farms LLC</td>
                  <td>NC</td>
                  <td>Certified Organic</td>
                  <td>8a</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/farms-profile/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/farms-profile/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>SC_O_1</td>
                  <td>1102</td>
                  <td>Bio Way Farm</td>
                  <td>SC</td>
                  <td>Naturally Grown</td>
                  <td>8b</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/farms-profile/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/farms-profile/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>GA_O_1</td>
                  <td>1350</td>
                  <td>Blue Heron Urban Farms...</td>
                  <td>GA</td>
                  <td>Conventional</td>
                  <td>7a</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/farms-profile/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/farms-profile/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>GA_C_1</td>
                  <td>4078</td>
                  <td>Bramblett Angus</td>
                  <td>GA</td>
                  <td>Naturally Grown</td>
                  <td>8a</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/farms-profile/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/farms-profile/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>GA_C_1</td>
                  <td>4078</td>
                  <td>Bramblett Angus</td>
                  <td>GA</td>
                  <td>Naturally Grown</td>
                  <td>8a</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/farms-profile/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/farms-profile/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>NC_C_2</td>
                  <td>296</td>
                  <td>Country Pickin' Farms</td>
                  <td>NC</td>
                  <td>Conventional</td>
                  <td>8a</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/farms-profile/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/farms-profile/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>NC_C_3</td>
                  <td>Davis</td>
                  <td>Davis Farms Shady Creek, LLC</td>
                  <td>NC</td>
                  <td>Certified Naturally Grown</td>
                  <td>8b</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/farms-profile/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/farms-profile/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>NC_O_1</td>
                  <td>10219</td>
                  <td>Guilford College Farm</td>
                  <td>NC</td>
                  <td>Certified Organic</td>
                  <td>7a</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/farms-profile/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/farms-profile/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>SC_C_2</td>
                  <td>1107</td>
                  <td>H and G Produce</td>
                  <td>SC</td>
                  <td>Conventional</td>
                  <td>8a</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/farms-profile/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/farms-profile/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>GA_O_2</td>
                  <td>8720</td>
                  <td>Hearts of Harvest Farm</td>
                  <td>GA</td>
                  <td>Naturally Grown</td>
                  <td>7a</td>
                  <td>
                    <div className="actionbtn d-flex">
                      <NavLink  to="/farms-profile/view" title="View"><span className="material-icons-outlined">visibility</span></NavLink>
                      <NavLink  to="/farms-profile/edit" title="Edit"><span className="material-icons">edit</span></NavLink>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
              <div className="totalResul">Result found (15)</div>
              <div className="paging">
                <a href="#">&lt;&lt;</a>
                <a href="#">&lt;</a>
                <a href="#" className="number">1</a>
                <a href="#">&gt;</a>
                <a href="#">&gt;&gt;</a>
              </div>
              <div></div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default FarmsProfile;
