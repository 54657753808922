import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
const FarmsProfileEdit: FC = () => {
  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4 pt-md-5 pb-md-5">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">Edit Carter Farms Information</span>
              NC_C_1
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 pt-md-5 pb-md-5 mt-2 whtBg">
          <div className="container">
            <ul className="d-flex flex-wrap p-0 viewList">
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Farm ID</span>
                  <input type="text" className="loginInput" defaultValue="NC_C_1" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">USDA / FSA</span>
                  <input type="text" className="loginInput" defaultValue="1663" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Acreage</span>
                  <input type="text" className="loginInput" defaultValue="10 - about 5 in pasture/field" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Planting Zone</span>
                  <input type="text" className="loginInput" defaultValue="7b" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Tract</span>
                  <input type="text" className="loginInput" defaultValue="2415" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Field Numbers</span>
                  <input type="text" className="loginInput" defaultValue="3,4,5,6" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Commodities Grown</span>
                  <textarea className="loginInput">Plan for naturally grown, organic certification if possible; tomatoes, barley, squash, onion, garlic...</textarea>
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Type Of Operation</span>
                  <input type="text" className="loginInput" defaultValue="Naturally Grown" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Years In Production</span>
                  <input type="text" className="loginInput" defaultValue="5" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Beginning Farmer</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Limited Resource Farmer</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Duty Service Member (Veteran / Active)</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Race / Ethnicity</span>
                  <input type="text" className="loginInput" defaultValue="White" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Self Describe Race Ethnicity</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Gender</span>
                  <input type="text" className="loginInput" defaultValue="Male" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Self Describe Gender</span>
                  <input type="text" className="loginInput" defaultValue="Male" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Use Cover Crops</span>
                  <input type="text" className="loginInput" defaultValue="No" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Enrolled In Other Nrcs Program </span>
                  <input type="text" className="loginInput" defaultValue="No" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Other Nrcs Programs</span>
                  <input type="text" className="loginInput" defaultValue="We have applied for but have not been qualified" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Willing To Dedicate 10 Acres</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field"></span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Willing Commit Enrolled Acreage (Spring 2028)</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Reason Not Willing Commit Enrolled Acreage (Spring 2028)</span>
                  <input type="text" className="loginInput" defaultValue="Yes, although not our entire acreage, we could commit 1 acre" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Willing Provide Periodic Economic Data</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Reason Not Willing Provide Periodic Economic Data</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Willing Allow Scheduled Visits</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Reason Not Willing Provide Periodic Economic Data</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Willing Allow Scheduled Visits</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Nrcs 1270</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">W_9</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">FSA Waiver</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Equipment Survey</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Cash Crop Survey</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Enrolled Acreage</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Equipment Type</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Tractor hp</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">3 pt Hitch Category</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Mower Bush Hog</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Tiller</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Tractor Hydraulic</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Tractor Electric</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Seed Drill</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Mulch Layer</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Transplanter</span>
                  <input type="text" className="loginInput" defaultValue="Yes" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Vehicle To Flatbed Trailer</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="position-relative">
                  <span className="field">Type Of Truck</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12">
                <div className="position-relative">
                  <span className="field">How unload Equipment From Trailer</span>
                  <input type="text" className="loginInput" defaultValue="N/A" />
                </div>
              </li>
              <li className="col-12">
                <div className="position-relative">
                  <span className="field">Comments</span>
                  <textarea className="loginInput">Not sure if this is open to new farms, but I am interested in cover crops and organic, so seems like a good fit. I am enrolled in the Arcadia Veteran Farmer reserve for 2024 since I am new to farming. I am also interested in regenerative, no till methods.</textarea>
                </div>
              </li>
            </ul>
            <div className="pt-5 text-end">
              <NavLink to="/farms-profile/view">
                <button type="button" className="formBtn cancel">
                  Cancel
                </button>
              </NavLink>
              <NavLink to="/farms-profile">
                <button type="button" className="ms-3 formBtn">
                Save Details
                </button>
              </NavLink>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default FarmsProfileEdit;
