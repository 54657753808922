import {Routes,Route} from 'react-router-dom';
 import Login from '../pages/accounts/Login';
import Dashboard from '../pages/accounts/dashboard';
import Farms from '../pages/farms/farms';
import FarmsView from '../pages/farms/farms-view';
import FarmsEdit from '../pages/farms/farms-edit';
import FarmsProfile from '../pages/farms-profile/farms-profile';
import FarmsProfileView from '../pages/farms-profile/farms-profile-view';
import FarmsProfileEdit from '../pages/farms-profile/farms-profile-edit';



export default function Root() {
    return (
        <>
            <Routes>
                <Route path="" element={<Login />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="farms" element={<Farms />} />
                <Route path="farms/view" element={<FarmsView />} />
                <Route path="farms/edit" element={<FarmsEdit />} />
                <Route path="farms-profile" element={<FarmsProfile />} />
                <Route path="farms-profile/view" element={<FarmsProfileView />} />
                <Route path="farms-profile/edit" element={<FarmsProfileEdit />} />
                <Route path="farms/edit/:FarmId/*" element={<FarmsEdit />} />
                <Route path="farms/view/:FarmId/*" element={<FarmsView />} />
            </Routes>
        </>
        
    )
}


