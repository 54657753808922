import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { string, object, date, ref } from "yup";
import {
  AddFarmData,
  GetClientsDetails,
} from "../../redux/actions/farmsAction";
import { FormikProps, useFormik } from "formik";
import { FarmDetails } from "../../Typings/dataListing";
import { errorMsgs } from "../../context/notify";

const FarmsEdit: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { FarmId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getfarmdate();
  }, [dispatch]);

  const getfarmdate = async () => {
    var payload = {
      farm_Id: FarmId,
    };
    try {
      dispatch(
        GetClientsDetails(payload, (data: any) => {
          const farmData = data?.data;
          if (farmData) {
            formik.setValues({
              farm_id: farmData.farm_id || "",
              usda_fsa_num: farmData.usda_fsa_num || 0,
              first_name: farmData.first_name || "",
              last_name: farmData.last_name || "",
              farm_name: farmData.farm_name || "",
              county: farmData.county || "",
              field_day: farmData.field_day || "",
              mailing_address_line_1: farmData.mailing_address_line_1 || "",
              mailing_address_line_2: farmData.mailing_address_line_2 || "",
              mailing_address_city: farmData.mailing_address_city || "",
              mailing_address_state: farmData.mailing_address_state || "",
              mailing_address_zip_postal_code:
                farmData.mailing_address_zip_postal_code || "",
              farm_address_line_1: farmData.farm_address_line_1 || "",
              farm_address_line_2: farmData.farm_address_line_2 || "",
              farm_address_city: farmData.farm_address_city || "",
              farm_address_state: farmData.farm_address_state || "",
              farm_address_zip_postal_code:
                farmData.farm_address_zip_postal_code || "",
              latitude: farmData.latitude || 0,
              longitude: farmData.longitude || 0,
              mobile_phone_number: farmData.mobile_phone_number || "",
              work_phone_number: farmData.work_phone_number || "",
              email_address_1: farmData.email_address_1 || "",
              email_address_2: farmData.email_address_2 || "",
              status: farmData.status || "",
              ineligible_reason: farmData.ineligible_reason || "",
              notes: farmData.notes || "",
            });
          }
        })
      );
    } catch (error) {
      console.error("Error fetching user types:", error);
    } finally {
    }
  };

  const formik: FormikProps<FarmDetails> = useFormik({
    initialValues: {
      farm_id: "",
      usda_fsa_num: 0,
      first_name: "",
      last_name: "",
      farm_name: "",
      county: "",
      field_day: "",
      mailing_address_line_1: "",
      mailing_address_line_2: "",
      mailing_address_city: "",
      mailing_address_state: "",
      mailing_address_zip_postal_code: "",
      farm_address_line_1: "",
      farm_address_line_2: "",
      farm_address_city: "",
      farm_address_state: "",
      farm_address_zip_postal_code: "",
      latitude: 0,
      longitude: 0,
      mobile_phone_number: "",
      work_phone_number: "",
      email_address_1: "",
      email_address_2: "",
      status: "",
      ineligible_reason: "",
      notes: "",
    },
    // validationSchema: object({
    //   name: string().required("Project name is required"),
    //   projectNumber: string().required("Project number is required"),
    //   projectStartDate: date().required("Start date is required"),
    //   projectEndDate: date()
    //     .required("End date is required")
    //     .min(ref("projectStartDate"), "End date must be after the start date"),
    // })
    // ,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      dispatch(
        AddFarmData(values, (response: any) => {
          formik.resetForm();
          try {
            if (response.statusCode === 0) {
              errorMsgs(response.message);
            } else if (response.statusCode === 1) {
              navigate("/farms");
            } else if (response.statusCode === 2) {
              navigate("/farms");
            }
          } catch (error) {
          } finally {
            setSubmitting(false);
          }
        })
      );
    },
  });

  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4 pt-md-5 pb-md-5">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">Edit Carter Farms Information</span>{" "}
              {formik.values.farm_id}
            </div>
          </div>
        </section>

        <section className="pt-4 pb-4 pt-md-5 pb-md-5 mt-2 whtBg">
          <div className="container">
            <form onSubmit={formik.handleSubmit}>
              <div className="subHd mb-4">Farm Information</div>
              <ul className="d-flex flex-wrap p-0 viewList">
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">First Name</span>
                    <input
                      type="text"
                      name="first_name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Last Name</span>
                    <input
                      type="text"
                      name="last_name"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Mobile</span>
                    <input
                      type="text"
                      name="mobile_phone_number"
                      value={formik.values.mobile_phone_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Work Phone</span>
                    <input
                      type="text"
                      name="work_phone_number"
                      value={formik.values.work_phone_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                    {/* <input type="text" className="loginInput" value="N/A" /> */}
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Email Address 1</span>
                    <input
                      type="text"
                      name="email_address_1"
                      value={formik.values.email_address_1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Email Address 2</span>
                    <input
                      type="text"
                      name="email_address_2"
                      value={formik.values.email_address_2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Mailing Address Line 1</span>
                    <input
                      type="text"
                      name="mailing_address_line_1"
                      value={formik.values.mailing_address_line_1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Mailing Address Line 2</span>
                    <input
                      type="text"
                      name="mailing_address_line_2"
                      value={formik.values.mailing_address_line_2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-4">
                  <div className="position-relative">
                    <span className="field">City</span>
                    <input
                      type="text"
                      name="mailing_address_city"
                      value={formik.values.mailing_address_city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-4">
                  <div className="position-relative">
                    <span className="field">State</span>
                    <input
                      type="text"
                      name="mailing_address_state"
                      value={formik.values.mailing_address_state}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-4">
                  <div className="position-relative">
                    <span className="field">Zip Code</span>
                    <input
                      type="text"
                      name="mailing_address_zip_postal_code"
                      value={formik.values.mailing_address_zip_postal_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">USDA Number</span>
                    <input
                      type="text"
                      name="usda_fsa_num"
                      value={formik.values.usda_fsa_num}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Farm Name</span>
                    <input
                      type="text"
                      name="farm_name"
                      value={formik.values.farm_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">County</span>
                    <input
                      type="text"
                      name="county"
                      value={formik.values.county}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Field Day</span>
                    <input
                      type="text"
                      name="field_day"
                      value={formik.values.field_day}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Farm Address Line 1</span>
                    <input
                      type="text"
                      name="farm_address_line_1"
                      value={formik.values.farm_address_line_1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Farm Address Line 2</span>
                    <input
                      type="text"
                      name="farm_address_line_2"
                      value={formik.values.farm_address_line_2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-4">
                  <div className="position-relative">
                    <span className="field">Farm City</span>
                    <input
                      type="text"
                      name="farm_address_city"
                      value={formik.values.farm_address_city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-4">
                  <div className="position-relative">
                    <span className="field">Farm State</span>
                    <input
                      type="text"
                      name="farm_address_state"
                      value={formik.values.farm_address_state}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-4">
                  <div className="position-relative">
                    <span className="field">Farm Zip Code</span>
                    <input
                      type="text"
                      name="farm_address_zip_postal_code"
                      value={formik.values.farm_address_zip_postal_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Latitude</span>
                    <input
                      type="text"
                      name="latitude"
                      value={formik.values.latitude}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Longitude</span>
                    <input
                      type="text"
                      name="longitude"
                      value={formik.values.longitude}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Status</span>
                    <input
                      type="text"
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="position-relative">
                    <span className="field">Reason</span>
                    <input
                      type="text"
                      name="ineligible_reason"
                      value={formik.values.ineligible_reason}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
                <li className="col-12">
                  <div className="position-relative">
                    <span className="field">Notes</span>
                    <input
                      type="text"
                      name="notes"
                      value={formik.values.notes}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="loginInput"
                    />
                  </div>
                </li>
              </ul>
              <div className="pt-5 text-end">
                <NavLink
                  to={{
                    pathname: "/farms/view/" + formik.values.farm_id,
                  }}
                  title="View"
                >
                  <button type="button" className="formBtn cancel">
                    Cancel
                  </button>
                </NavLink>
                <button type="submit" className="ms-3 formBtn">
                  Save
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default FarmsEdit;
