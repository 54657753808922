import { FC, useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import { bool, number } from "yup";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { getallfarmsList } from "../../redux/actions/farmsAction";
import Pagination from "../../components/pagination";
const Farms: FC = () => {
  const [CurrentPage, SetCurrentPage] = useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const [farm_name, setfarm_name] = useState("");
  const [FSA, setFSA] = useState("");
  const [farm_address_state, setfarm_address_state] = useState("");
  const [status, setstatus] = useState("");
  const [Farmlist, SetFarmList] = useState({
    data: [],
    pageIndex: number,
    pageSize: number,
    totalrecords: number,
    totalPages: number,
    hasPreviousPage: bool,
    hasNextPage: bool,
  });

  useEffect(() => {
    searchFarm();
  }, [CurrentPage, status, FSA, farm_name, farm_address_state]);
  const searchFarm = () => {
    var payload = {
      PageIndex: CurrentPage,
      PageSize: 10,
      SkipRow: 10 * CurrentPage,
      SortBy: "CreatedOn",
      SortOrder: "desc",
      usda_fsa_num: FSA,
      farm_id: farm_name,
      farm_address_state: farm_address_state,
      status: status,
    };
    dispatch(
      getallfarmsList(payload, (response: any) => {
        SetFarmList(response);
      })
    );
  };

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <div>
      <Header />
      <div className="midCon pb-2">
        <section className="pt-4 pb-4">
          <div className="container">
            <div className="loginHd mb-1">
              <span className="d-block">Farms</span>
            </div>
            <div className="bradcrumb">
              <NavLink to="/dashboard">Dashboard</NavLink> &gt; Farms
            </div>
          </div>
        </section>
        <section className="filterCon">
          <div className="container">
            <div className="d-flex flex-wrap align-items-center pt-3 pb-3">
              <div className="searchBy">Search By</div>
              <ul className="d-flex flex-wrap filterBox">
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">Farm ID</span>
                    <input
                      type="text"
                      className="filterInput"
                      placeholder="VA_O_2"
                      onChange={(event) => setfarm_name(event.target.value)}
                    />
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">FSA#</span>
                    <input
                      type="text"
                      className="filterInput"
                      onChange={(event) => setFSA(event.target.value)}
                    />
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">State</span>
                    <input
                      type="text"
                      onChange={(event) =>
                        setfarm_address_state(event.target.value)
                      }
                      className="filterInput"
                    />
                  </div>
                </li>
                <li className="col-6 col-sm-3">
                  <div className="position-relative">
                    <span className="field">Status</span>
                    <input
                      type="text"
                      className="filterInput"
                      onChange={(event) => setstatus(event.target.value)}
                    />
                  </div>
                </li>
                <li className="btn">
                  <button type="button">
                    <span
                      className="material-icons"
                      onClick={() => {
                        SetCurrentPage(1);
                        searchFarm();
                      }}
                    >
                      search
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 mt-2 whtBg">
          <div className="container">
            <div className="tableBox">
              <table>
                <thead>
                  <tr>
                    <th>Farm ID</th>
                    <th>Farm Name</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Farm City</th>
                    <th>Farm State</th>
                    <th>Latitude</th>
                    <th>Longitude</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {Farmlist.data && (
                  <tbody>
                    {Farmlist.data.map((item: any) => (
                      <tr key={"farm_id" + item.farm_id}>
                        <td>{item.farm_id}</td>
                        <td>{item.farm_name}</td>
                        <td>{item.first_name}</td>
                        <td>{item.last_name}</td>
                        <td>{item.farm_address_city}</td>
                        <td>{item.farm_address_state}</td>
                        <td>{item.latitude}</td>
                        <td>{item.longitude}</td>
                        <td>
                          <div className="actionbtn d-flex">
                            <NavLink
                              to={{
                                pathname: "/farms/view/" + item.farm_id,
                              }}
                              title="View"
                            >
                              <span className="material-icons-outlined">
                                visibility
                              </span>
                            </NavLink>
                            <NavLink
                              to={{
                                pathname: "/farms/edit/" + item.farm_id,
                              }}
                              title="Edit"
                            >
                              <span className="material-icons">edit</span>
                            </NavLink>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>

            <div className="d-flex justify-content-between align-items-center pt-3 pb-3">
              <div className="totalResul">{/* {Farmlist.totalrecords} */}</div>
              <Pagination
                GoToPage={(value: number) => {
                  SetCurrentPage(value);
                }}
                PageIndex={Number(Farmlist.pageIndex)}
                TotalPages={Number(Farmlist.totalPages)}
                hasNextPage={Boolean(Farmlist.hasNextPage)}
                hasPreviousPage={Boolean(Farmlist.hasPreviousPage)}
              />

              {/* <div className="paging">
                <a href="#">&lt;&lt;</a>
                <a href="#">&lt;</a>
                <a href="#" className="number">
                  1
                </a>
                <a href="#">&gt;</a>
                <a href="#">&gt;&gt;</a>
              </div> */}
              <div></div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Farms;
