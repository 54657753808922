import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import Farms from "../../assets/img/farms.svg";
import FarmsProfile from "../../assets/img/farms-profile.svg";
import ProjectGrantees from "../../assets/img/project-grantees.svg";
import Producers from "../../assets/img/producers.svg";
import Fields from "../../assets/img/fields.svg";
import Partners from "../../assets/img/partners.svg";
const Dashboard: FC = () => {
  return (
    <div>
      <Header />
      <section className="midCon pt-5 pb-5">
        <div className="container">
          <div className="loginHd mb-4 mb-xl-5">
            <span className="d-block">Welcome Kristie</span>
            Southern Piedmont, running through Virginia, North and South
            Carolina, Georgia, and Alabama
          </div>
          <ul className="dbLinks p-0 d-flex flex-wrap text-center">
            <li className="col-6 col-sm-4">
              <NavLink to="/farms">
                <span className="icon">
                  <img src={Farms} alt="Farms" />
                </span>{" "}
                Farms
              </NavLink>
            </li>
            <li className="col-6 col-sm-4">
            <NavLink to="/farms-profile">
                <span className="icon">
                  <img src={FarmsProfile} alt="Farms Profile" />
                </span>{" "}
                Farms Profile
              </NavLink>
            </li>
            <li className="col-6 col-sm-4">
              <a href="#">
                <span className="icon">
                  <img src={ProjectGrantees} alt="Project Grantees" />
                </span>{" "}
                Project Grantees
              </a>
            </li>
            <li className="col-6 col-sm-4">
              <a href="#">
                <span className="icon">
                  <img src={Producers} alt="Producers" />
                </span>{" "}
                Producers
              </a>
            </li>
            <li className="col-6 col-sm-4">
              <a href="#">
                <span className="icon">
                  <img src={Fields} alt="Fields" />
                </span>{" "}
                Fields
              </a>
            </li>
            <li className="col-6 col-sm-4">
            <NavLink to="#">
                <span className="icon">
                  <img src={Partners} alt="Partners" />
                </span>{" "}
                Partners
              </NavLink>
            </li>
          </ul>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Dashboard;
