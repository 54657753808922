import { FC } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from '../../assets/img/logo.png';
import Dropdown from 'react-bootstrap/Dropdown';

const Header: FC = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/login');
    };
    return (
       <>
            <header className="header">
                <div className="container d-flex align-items-center justify-content-between">
                <div className="logo"><NavLink  to="/dashboard"><img alt="" src={Logo} /></NavLink></div>
                    <div>
                    <Dropdown align="end" className="acLink">
                        <Dropdown.Toggle className="d-flex align-items-center"><span className="icon d-inline-flex align-items-center justify-content-center">KW</span> Kristie</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#">Kristie Wendelberger</Dropdown.Item>
                            <Dropdown.Item href="/">Sign Out</Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </header>
       </>
    );
}

export default Header;