import API from "../../config/api";
import { Success } from "../../context/notify";
import { accountActionTypes } from "../constants/ActionTypes";



export const submitLoginData = (defaultparams: any,callBack?: any) => async (dispatch: any) => {
    await API().post('api/Auth/Login', defaultparams).then((response: any) => {
        if (response.status === 200 && callBack) {
            //dispatch({ type: accountActionTypes.FATCH_LOGIN, payload: response.data });
            callBack(response.data);
        }
    }).catch((err) => { 
        callBack(err); 
    });
}

export const forgotPassword = (defaultparams: any, callBack?: any) => async (dispatch: any) => {
    await API().post('api/LoginAuth/ForgotPassword', defaultparams).then((response:any) =>{

    if (response.status === 200 && callBack) {
        callBack(response.data);       
       // dispatch({ type: accountActionTypes.FATCH_LOGIN, payload: response.data });
    }
});
}

export const userResetPassword = (defaultparams: any,callBack?: any) => async (dispatch: any) => {
    await API().post('api/LoginAuth/ResetPassword', defaultparams).then((response: any) => {
        if (response.status === 200 && callBack) {
       //     dispatch({ type: accountActionTypes.FATCH_CHANGE_PASSWORD, payload: response.data });
            callBack(response.data);
        }
    }).catch((err) => { 
        callBack(err); 
    });
}

export const resetPassword = (resetPasswordInfo: any, callBack?: any) => async (dispatch: any) => {
    await API({}, true, true).post('reset_password', resetPasswordInfo)
        .then((response: any) => {
            if (response.status === 200) {
                Success(response.data.message);
                if (callBack) {
                    callBack();
                }
            }
            dispatch({ type: accountActionTypes.USER_RESET_PASSWORD_LINK, payload: response.data })
        })
        .catch((error: any) => {
            // navigate("/");
        });
}

export const getProfile = () => async (dispatch: any) => {
    const response = await API("", true).get('getProfile');
    dispatch({ type: accountActionTypes.GET_PROFILE_INFO, payload: response.data });
}

export const updateProfile = (defaultparams: any, callBack?: any) => async (dispatch: any) => {
    const response = await API("", true).post('editProfile', defaultparams);
    if (response.status === 200) {
        Success(response.data.message);
        if (callBack) {
            callBack();
        }
    }
    dispatch({ type: accountActionTypes.UPDATE_PROFILE, payload: response.data });
}

export const changePassword = (defaultparams: any, callBack?: any) => async (dispatch: any) => {
    const response = await API("", true).post('changePassword', defaultparams);
    if (response.status === 200) {
        Success(response.data.message);
        if (callBack) {
            callBack();
        }
    }
    dispatch({ type: accountActionTypes.FATCH_CHANGE_PASSWORD, payload: response.data });
}

export const logoutUser = (callBack?: any) => async (dispatch: any) => {
    const response = await API("", true).get('logout');
    if (response.status === 200) {
        Success(response.data.message);
        if (callBack) {
            callBack(response);
        }
        else {
            callBack({ status: 0, message: 'Unexpected error occurred' });
        }
    }
    dispatch({ type: accountActionTypes.GET_LOGOUT, payload: response.data });
}

export const VerifyOtp = (userId: number, userAccessCode: string, callBack?: any) => async (dispatch: any) => {
    const defaultparams = { userId, userAccessCode }; // Set params with userId and userAccessCode

    await API().post('api/LoginAuth/ValidateUser?userId=' + userId + '&userAccessCode=' + userAccessCode).then((response: any) => {
        if (response.status === 200 && callBack) {
            dispatch({ type: accountActionTypes.VERIFY_OTP, payload: response.data });
            callBack(response.data);
        }
    }).catch((err) => {
        if (callBack) callBack(err);
    });
};